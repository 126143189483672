import mocks from '@/@fake-db/mockAdapters'
import samples from '@/@fake-db/responseSamples'
import dbFunctions from '@/@fake-db/dbFunctions'

// A package for generating mock data (https://github.com/nuysoft/Mock/wiki/Syntax-Specification)
import Mock from 'mockjs'


const ApiMap = {

  // Records
  '/kioskmanage/GetMeasureBPList': (config) => {
    const total = 124
    const { pageIndex, pageSize } = dbFunctions.getSearchParams(config)
    const currentPageSize = dbFunctions.getCurrentPageSize({ total, pageIndex, pageSize })
    const template = {
      'return_code': 0,
      'result_msg': 'Success',
      'data': { total, pageIndex, pageSize },
    }
    let i = pageIndex * pageSize + 1
    template.data[`measureBPItems|${currentPageSize}`] = [{
      'systolic|110-150': 127,
      'diastolic|60-100': 82,
      'pulse|60-140': 123,
      'memberPhoto': null,
      'memberName': '@cname',
      'kioskName': () => `Kiosk No.${Mock.Random.integer(1, 5)}`,
      'referenceCode': /^[A-Z][A-Z0-9]{5}$/,
      'measureTime': () => dbFunctions.getRandomDatetime(),
      'bedID': () => i,
      'floorName': '1/F',
      'roomName': '101',
      'bedNumber': function () { return `${this.roomName}-${i++}` },
    }]
    return [200, Mock.mock(template)]
  },
  '/kioskmanage/GetMeasureHandgripList': (config) => {
    const total = 124
    const { pageIndex, pageSize } = dbFunctions.getSearchParams(config)
    const currentPageSize = dbFunctions.getCurrentPageSize({ total, pageIndex, pageSize })
    const template = {
      'return_code': 0,
      'result_msg': 'Success',
      'data': { total, pageIndex, pageSize },
    }
    let i = pageIndex * pageSize + 1
    template.data[`measureHandgripItems|${currentPageSize}`] = [{
      'handgrip|40-90': 60,
      'memberPhoto': null,
      'memberName': '@cname',
      'kioskName': () => `Kiosk No.${Mock.Random.integer(1, 5)}`,
      'referenceCode': /^[A-Z][A-Z0-9]{5}$/,
      'measureTime': () => dbFunctions.getRandomDatetime(),
      'bedID': () => i,
      'floorName': '1/F',
      'roomName': '101',
      'bedNumber': function () { return `${this.roomName}-${i++}` },
    }]
    return [200, Mock.mock(template)]
  },
  '/kioskmanage/GetMeasureHeightList': (config) => {
    const total = 124
    const { pageIndex, pageSize } = dbFunctions.getSearchParams(config)
    const currentPageSize = dbFunctions.getCurrentPageSize({ total, pageIndex, pageSize })
    const template = {
      'return_code': 0,
      'result_msg': 'Success',
      'data': { total, pageIndex, pageSize },
    }
    let i = pageIndex * pageSize + 1
    template.data[`measureHeightItems|${currentPageSize}`] = [{
      'height|150-188': 165,
      'memberPhoto': null,
      'memberName': '@cname',
      'kioskName': () => `Kiosk No.${Mock.Random.integer(1, 5)}`,
      'referenceCode': /^[A-Z][A-Z0-9]{5}$/,
      'measureTime': () => dbFunctions.getRandomDatetime(),
      'bedID': () => i,
      'floorName': '1/F',
      'roomName': '101',
      'bedNumber': function () { return `${this.roomName}-${i++}` },
    }]
    return [200, Mock.mock(template)]
  },
  '/kioskmanage/GetMeasureSpO2List': (config) => {
    const total = 124
    const { pageIndex, pageSize } = dbFunctions.getSearchParams(config)
    const currentPageSize = dbFunctions.getCurrentPageSize({ total, pageIndex, pageSize })
    const template = {
      'return_code': 0,
      'result_msg': 'Success',
      'data': { total, pageIndex, pageSize },
    }
    let i = pageIndex * pageSize + 1
    template.data[`measureSpO2Items|${currentPageSize}`] = [{
      'spO2|85-98': 95,
      'memberPhoto': null,
      'memberName': '@cname',
      'kioskName': () => `Kiosk No.${Mock.Random.integer(1, 5)}`,
      'referenceCode': /^[A-Z][A-Z0-9]{5}$/,
      'measureTime': () => dbFunctions.getRandomDatetime(),
      'bedID': () => i,
      'floorName': '1/F',
      'roomName': '101',
      'bedNumber': function () { return `${this.roomName}-${i++}` },
    }]
    return [200, Mock.mock(template)]
  },
  '/kioskmanage/GetMeasureBTList': (config) => {
    const total = 124
    const { pageIndex, pageSize } = dbFunctions.getSearchParams(config)
    const currentPageSize = dbFunctions.getCurrentPageSize({ total, pageIndex, pageSize })
    const template = {
      'return_code': 0,
      'result_msg': 'Success',
      'data': { total, pageIndex, pageSize },
    }
    let i = pageIndex * pageSize + 1
    template.data[`measureBTItems|${currentPageSize}`] = [{
      'temperature|35-39.1-1': 1,
      'memberPhoto': null,
      'memberName': '@cname',
      'kioskName': () => `Kiosk No.${Mock.Random.integer(1, 5)}`,
      'referenceCode': /^[A-Z][A-Z0-9]{5}$/,
      'measureTime': () => dbFunctions.getRandomDatetime(),
      'bedID': () => i,
      'floorName': '1/F',
      'roomName': '101',
      'bedNumber': function () { return `${this.roomName}-${i++}` },
    }]
    return [200, Mock.mock(template)]
  },
  '/kioskmanage/GetMeasureWeightList': (config) => {
    const total = 124
    const { pageIndex, pageSize } = dbFunctions.getSearchParams(config)
    const currentPageSize = dbFunctions.getCurrentPageSize({ total, pageIndex, pageSize })
    const template = {
      'return_code': 0,
      'result_msg': 'Success',
      'data': { total, pageIndex, pageSize },
    }
    let i = pageIndex * pageSize + 1
    template.data[`measureWeightItems|${currentPageSize}`] = [{
      'weight|42-120': 1,
      'memberPhoto': null,
      'memberName': '@cname',
      'kioskName': () => `Kiosk No.${Mock.Random.integer(1, 5)}`,
      'referenceCode': /^[A-Z][A-Z0-9]{5}$/,
      'measureTime': () => dbFunctions.getRandomDatetime(),
      'bedID': () => i,
      'floorName': '1/F',
      'roomName': '101',
      'bedNumber': function () { return `${this.roomName}-${i++}` },
    }]
    return [200, Mock.mock(template)]
  },
  '/kioskmanage/GetMeasureGlucoseList': (config) => {
    const total = 124
    const { pageIndex, pageSize } = dbFunctions.getSearchParams(config)
    const currentPageSize = dbFunctions.getCurrentPageSize({ total, pageIndex, pageSize })
    const template = {
      'return_code': 0,
      'result_msg': 'Success',
      'data': { total, pageIndex, pageSize },
    }
    let i = pageIndex * pageSize + 1
    template.data[`measureGlucoseItems|${currentPageSize}`] = [{
      'isEmptyStomach|1': true,
      'glucose|2-12.1': 1,
      'memberPhoto': null,
      'memberName': '@cname',
      'kioskName': () => `Kiosk No.${Mock.Random.integer(1, 5)}`,
      'referenceCode': /^[A-Z][A-Z0-9]{5}$/,
      'measureTime': () => dbFunctions.getRandomDatetime(),
      'bedID': () => i,
      'floorName': '1/F',
      'roomName': '101',
      'bedNumber': function () { return `${this.roomName}-${i++}` },
    }]
    return [200, Mock.mock(template)]
  },


  // Environment
  '/kioskmanage/GetKioskList': (config) => {
    const total = 22
    const { pageIndex = 0, pageSize = 20 } = dbFunctions.getSearchParams(config)
    const currentPageSize = dbFunctions.getCurrentPageSize({ total, pageIndex, pageSize })
    const template = {
      'return_code': 0,
      'result_msg': 'Success',
      'data': { total, pageIndex, pageSize },
    }
    let i = pageIndex * pageSize + 1
    template.data[`kiosks|${currentPageSize}`] = [{
      'kioskToken': function () {
        return `Kiosk-M${String(i)
          .padStart(3, '0')}`
      },
      'kioskName': function () {
        return `mockPad-${String(i)
          .padStart(3, '0')}`
      },
      'kioskEnvironment': function() {
        return i <= 7 ? 'Production' : 'UAT'
      },
      'kioskPlatform|1': function() {
        return i <= 7 ? 'Android' : Mock.Random.pick(['Android', 'Windows', null], 1, 1)
      },
      'isRegistered|1': true,
      '_nth': () => i++,
    }]
    return [200, Mock.mock(template)]
  },
  '/kioskmanage/GetKioskEnvOptions': () => [200, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': {
      'environments': [
        'UAT',
        'Production',
      ],
      'platforms': [
        'Windows',
        'Android',
      ],
    },
  }],
  '/kioskmanage/GetKiosk': (config) => {
    const template = {
      'return_code': 0,
      'result_msg': 'Success',
      'data': {
        'baseInfo': {
          'kioskToken': 'Kiosk-M001',
          'kioskName': 'mockPad-001',
          'kioskEnvironment|1': [
            // 'UAT',
            'Production',
          ],
          'kioskPlatform': 'Android',
        },
        'measureConfig': {
          'isShowCognition': false,
          'isShowGripStrength': false,
          'isShowHeight': true,
          'isShowWeight': true,
          'isShowSpO2': true,
          'isShowBP': true,
          'isShowBodyTemp': true,
          'isShowGlucose': true,
          'isLoginFaceID': false,
          'isLoginQrCode': true,
          'isLoginNFC': true,
          'spO2SampleSize': 1,
          'spO2Range': 2,
          'gripStrengthSampleSize': 10,
          'gripStrengthBackgroundSampleSize': 10,
          'gripStrengthRange': 30,
          'gripStrengthMinimumIncrease': 0.1,
          'bodyTempMinimum': 35,
          'bodyTempRange': 1,
          'bodyTempSampleSize': 1,
          'heightMinimum': 130,
          'heightRange': 2,
          'heightOffsetM': 1,
          'heightOffsetC': 0,
          'heightSampleSize': 5,
          'heightMeasureDelaySeconds': 3,
          'weightMinimum': 30,
          'weightRange': 2,
          'weightOffsetM': 1,
          'weightOffsetC': 0,
          'weightSampleSize': 10,
          'weightMeasureDelaySeconds': 3,
          'faceIdentificationInterval': 750,
          'faceIdConfidenceFilter': 0.7,
          // 'faceCollectionID': 'Test_Face_Collection',
          'heightOfSensor': 206,
          'welPageWeightTimeSpan': 20000,
          'resultPageDisplayDuration': 20000,
          'measureSwitchDuration': 7000,
        },
      },
    }
    return [200, Mock.mock(template)]
  },
  '/kioskmanage/AddKiosk': () => [200, samples.Success],
  '/kioskmanage/UpdateKiosk': () => [200, samples.Success],
  '/kioskmanage/DeleteKiosk': () => [200, samples.Success],
  '/kioskmanage/ExportKioskList': () => [200, samples.IoTAccessError],


  // Facility
  '/kioskmanage/GetKioskFacilityList': (config) => {
    const total = 7
    const { pageIndex, pageSize } = dbFunctions.getSearchParams(config)
    const currentPageSize = dbFunctions.getCurrentPageSize({ total, pageIndex, pageSize })
    const template = {
      'return_code': 0,
      'result_msg': 'Success',
      'data': { total, pageIndex, pageSize },
    }
    let i = pageIndex * pageSize + 1
    template.data[`kioskFacilityItems|${currentPageSize}`] = [{
      'createTime': () => dbFunctions.getRandomDatetime(),
      'kioskID': () => i,
      'kioskToken': function () {
        return `Kiosk-M${String(i)
          .padStart(3, '0')}`
      },
      'kioskName': function () {
        return `mockPad-${String(i)
          .padStart(3, '0')}`
      },
      'companyName': '公司1',
      'facilityName': () => `院舍1-1`,
    }]
    return [200, Mock.mock(template)]
  },
  '/kioskmanage/GetKioskFacility': () => [200, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': {
      'kioskFacility': {
        'kioskID': 1,
        'kioskToken': 'Kiosk-M001',
        'kioskName': 'mockPad-001',
        'companyID': 1,
        'facilityID': 11,
      },
    },
  }],
  '/kioskmanage/UpdateKioskFacility': () => [200, samples.Success],


  // Report
  '/kioskmanage/DownloadMeasureReport': () => [200, samples.IoTAccessError],


  // Package
  '/kioskupdate/GetPackageList': (config) => {
    const total = 9
    const { pageIndex = 0, pageSize = 20 } = dbFunctions.getSearchParams(config)
    const currentPageSize = dbFunctions.getCurrentPageSize({ total, pageIndex, pageSize })
    const template = {
      'return_code': 0,
      'result_msg': 'Success',
      'data': { total, pageIndex, pageSize },
    }
    let i = pageIndex * pageSize + 1
    let [v_windows, v_android] = [1, 1]
    template.data[`packages|${currentPageSize}`] = [{
      'packageID': () => i++,
      'packageName': function () {
        if (this.platform === 'Windows') {
          return `Kiosk_${v_windows}.apk`
        } else {
          return `KioskLite_${v_android}.zip`
        }
      },
      'version': function () {
        if (this.platform === 'Windows') {
          return `0.0.0.${v_windows++}`
        } else {
          return `0.0.0.${v_android++}`
        }
      },
      'isMandatory|1': true,
      'remark': () => 'remark_' + Mock.Random.word(8),
      'platform|1': ['Android', 'Windows'],
      'uploadedTime': () => dbFunctions.getRandomDatetime(),
    }]
    return [200, Mock.mock(template)]
  },
  '/kioskupdate/CheckVersion': () => [200, samples.Success],
  '/kioskpackage/AddPackage': () => [200, samples.Success],
  '/kioskupdate/EditPackage': () => [200, samples.Success],
  '/kioskupdate/GetPackageInfo': (config) => {
    const template = {
      'return_code': 0,
      'result_msg': 'Success',
      'data': {
        'package': {
          'packageID': 1,
          'packageName': () => 'package_' + Mock.Random.word(4) + '.zip',
          'version': '0.0.0.1',
          'isMandatory|1': true,
          'remark': () => 'remark_' + Mock.Random.word(8),
          'platform|1': ['Android', 'Windows'],
          'uploadedTime': () => dbFunctions.getRandomDatetime(),
        },
      },
    }
    return [200, Mock.mock(template)]
  },
  '/kioskupdate/DeletePackage': () => [200, samples.Success],
  '/kioskupdate/GetKioskList': (config) => {
    const total = 7
    const { pageIndex = 0, pageSize = 20 } = dbFunctions.getSearchParams(config)
    const currentPageSize = dbFunctions.getCurrentPageSize({ total, pageIndex, pageSize })
    const template = {
      'return_code': 0,
      'result_msg': 'Success',
      'data': { total, pageIndex, pageSize },
    }
    let i = pageIndex * pageSize + 1
    template.data[`kiosks|${currentPageSize}`] = [{
      'kioskToken': function () {
        return `Kiosk-M${String(i)
          .padStart(3, '0')}`
      },
      'kioskName': function () {
        return `mockPad-${String(i)
          .padStart(3, '0')}`
      },
      'currentVersion|1': ['0.0.0.1', '0.0.0.2'],
      'targetVersion|1': ['0.0.0.2', '0.0.0.3'],
      'platform|1': ['Android', 'Windows'],
      '_nth': () => i++,
    }]
    return [200, Mock.mock(template)]
  },
  '/kioskupdate/UpgradeKiosk': () => [200, samples.Success],
  '/kioskupdate/CancelUpgrade': () => [200, samples.Success],
  '/kioskupdate/GetKiosk': (config) => {
    const template = {
      'return_code': 0,
      'result_msg': 'Success',
      'data': {
        "kiosk": {
          "companyID": 1,
          "facilityID": 11,
          "kioskID": 2,
          "kioskToken": "Kiosk-M001",
          "kioskName": "mockPad-001",
          "platform": "Android",
        },
      },
    }
    return [200, Mock.mock(template)]
  },


  // About Us
  '/kioskmanage/GetAboutUs': async (config) => {
    const content = await import('@/components/wangEditor/contentSample-kioskAboutUs').then(m => m.default)
    const template = {
      'return_code': 0,
      'result_msg': 'Success',
      'data': {
        'kioskAboutUs': {
          // 'facilityID': config.data?.facilityID ?? 1,
          'remark': ``,
          'html': `${content}`,
        },
      },
    }
    return [200, Mock.mock(template)]
  },
  '/kioskmanage/UpdateAboutUs': () => [200, samples.Success],


  // Member NFC
  '/membernfc/GetMemberNfcList': (config) => {
    const total = 124
    const { pageIndex, pageSize } = dbFunctions.getSearchParams(config)
    const currentPageSize = dbFunctions.getCurrentPageSize({ total, pageIndex, pageSize })
    const template = {
      'return_code': 0,
      'result_msg': 'Success',
      'data': { total, pageIndex, pageSize },
    }
    let i = pageIndex * pageSize + 1
    template.data[`memberNfcs|${currentPageSize}`] = [{
      'nfcTags': () => Mock.mock({
        'nfcTags|1-3': [/^[a-zA-Z0-9]{36}$/],
      }).nfcTags.join(','),
      'floorName': '樓層1',
      'roomName': '房間1',
      'photo': null,
      'status|1': ['MSF', 'MSBH', 'MSIH', 'MSE'],
      'memberID': () => i,
      'chineseName': '@CNAME',
      'englishName': '@NAME',
      'bedNumber': function () { return `101-${i++}` },
    }]
    return [200, Mock.mock(template)]
  },
  '/membernfc/GetMemberNfcInfo': (config) => {
    const template = {
      'return_code': 0,
      'result_msg': 'Success',
      'data': {},
    }
    template.data[`member`] = {
      'companyName': '公司1',
      'facilityName': '院舍1',
      'floorName': '1/F',
      'roomName': '101',
      'memberCode': 'SSR0100001',
      'nfcTags': () => Mock.mock({
        'nfcTags|2-3': [/^[a-zA-Z0-9]{36}$/],
      }).nfcTags,
      'memberID': 1,
      'chineseName': '陈小文',
      'englishName': 'Wanwan Chan',
      'bedNumber': '101-1',
    }
    return [200, Mock.mock(template)]
  },
  '/membernfc/UpdateMemberNfc': () => [200, samples.Success],


  // Member QRCode
  '/memberqrcode/GetMemberQrCodeList': (config) => {
    const total = 124
    const { pageIndex, pageSize } = dbFunctions.getSearchParams(config)
    const currentPageSize = dbFunctions.getCurrentPageSize({ total, pageIndex, pageSize })
    const template = {
      'return_code': 0,
      'result_msg': 'Success',
      'data': { total, pageIndex, pageSize },
    }
    let i = pageIndex * pageSize + 1
    template.data[`memberQrCodes|${currentPageSize}`] = [{
      'qrCode|1': [/[A-F0-9]{36}/, /[A-F0-9]{36}/, null],
      'externalQrCodes': () => Mock.mock({
        'qrCode|0-4': [/^[A-Z0-9]{36}$/],
      }).qrCode.join(','),
      'floorName': '樓層1',
      'roomName': '房間1',
      // 'photo': 'files/MemberPhoto/MemberPhoto_1_20220630182432241.jpg',
      'photo': null,
      'status|1': ['MSF', 'MSBH', 'MSIH', 'MSE'],
      'memberID': () => i,
      'memberCode': () => `Member-${i}`,
      'chineseName': '@CNAME',
      'englishName': '@NAME',
      'bedNumber': function () { return `101-${i++}` },
    }]
    return [200, Mock.mock(template)]
  },
  '/memberqrcode/GetMemberQrCodeInfo': (config) => {
    const template = {
      'return_code': 0,
      'result_msg': 'Success',
      'data': {},
    }
    let i = 1
    template.data[`member`] = {
      'companyName': '公司1',
      'facilityName': '院舍1',
      'floorName': '1/F',
      'roomName': '101',
      'memberCode': 'SSR0100001',
      'qrCode': /^[A-F0-9]{32}$/,
      'externalQrCodes|1-4': [{
        'organId': () => i++,
        'qrCode': /^[A-F0-9]{32}$/,
      }],
      'memberID': 1,
      'chineseName': '陈小文',
      'englishName': 'Wanwan Chan',
      'bedNumber': '101-1',
    }
    return [200, Mock.mock(template)]
  },
  '/memberqrcode/UpdateMemberQrCode': () => [200, samples.Success],


  // Member FaceID
  '/memberface/GetFaceIDList': (config) => {
    const total = 124
    const { pageIndex, pageSize } = dbFunctions.getSearchParams(config)
    const currentPageSize = dbFunctions.getCurrentPageSize({ total, pageIndex, pageSize })
    const template = {
      'return_code': 0,
      'result_msg': 'Success',
      'data': { total, pageIndex, pageSize },
    }
    let i = pageIndex * pageSize + 1
    template.data[`memberFaces|${currentPageSize}`] = [{
      'faceID|1': [/[A-F0-9]{32}/, /[A-F0-9]{32}/, null],
      'floorName': '樓層1',
      'roomName': '房間1',
      // 'photo': 'files/MemberPhoto/MemberPhoto_1_20220630182432241.jpg',
      'photo': null,
      'status|1': ['MSF', 'MSBH', 'MSIH', 'MSE'],
      'memberID': () => i,
      'memberCode': () => `Member-${i}`,
      'chineseName': '@CNAME',
      'englishName': '@NAME',
      'bedNumber': function () { return `101-${i++}` },
    }]
    return [200, Mock.mock(template)]
  },
  '/memberface/GetFaceID': (config) => {
    const template = {
      'return_code': 0,
      'result_msg': 'Success',
      'data': {},
    }
    template.data[`memberFace`] = {
      'companyName': '公司1',
      'facilityName': '院舍1',
      'floorName': '1/F',
      'roomName': '101',
      'memberCode': 'SSR0100001',
      'faceID': /^[A-F0-9]{32}$/,
      'memberID': 1,
      'chineseName': '陈小文',
      'englishName': 'Wanwan Chan',
      'bedNumber': '101-1',
    }
    return [200, Mock.mock(template)]
  },
  '/memberface/EditFaceID': () => [200, samples.Success],


  // CollectionID
  '/memberface/GetFaceCollectionList': (config) => {
    const total = 6
    const { pageIndex, pageSize } = dbFunctions.getSearchParams(config)
    const currentPageSize = dbFunctions.getCurrentPageSize({ total, pageIndex, pageSize })
    const template = {
      'return_code': 0,
      'result_msg': 'Success',
      'data': { total, pageIndex, pageSize },
    }
    let i = pageIndex * pageSize + 1
    let companyNum = 1
    let facilityNum = 1
    const stepFacility = 3
    template.data[`facilityFaces|${currentPageSize}`] = [{
      'facilityID': () => facilityNum,
      'facilityName': () => `院舍${companyNum}-${facilityNum}`,
      'facilityCode': /^[A-Z]{3}\d{2}$/,
      'collectionID|1': [/[A-F0-9]{32}/, /[A-F0-9]{32}/, null],
      'companyName': () => `公司${companyNum}`,
      '': () => {
        if (i % stepFacility === 0) {
          companyNum++
          facilityNum = 1
        } else {
          facilityNum++
        }
        i++
      },
    }]
    return [200, Mock.mock(template)]
  },
  '/memberface/GetFaceCollection': (config) => {
    const template = {
      'return_code': 0,
      'result_msg': 'Success',
      'data': {},
    }
    template.data[`facilityFace`] = {
      'companyName': '公司1',
      'facilityName': '院舍1-1',
      'facilityID': 1,
      'facilityCode': 'TAC01',
      'collectionID': /[A-F0-9]{32}/,
    }
    return [200, Mock.mock(template)]
  },
  '/memberface/EditFaceCollection': () => [200, samples.Success],


  // Member External
  '/memberexternal/GetExternalOrgans': (config) => {
    const total = 124
    const { pageIndex, pageSize } = dbFunctions.getSearchParams(config)
    const currentPageSize = dbFunctions.getCurrentPageSize({ total, pageIndex, pageSize })
    const template = {
      'return_code': 0,
      'result_msg': 'Success',
      'data': {},
    }
    let i = 1
    template.data[`organs|4`] = [
      {
        "organID": () => i,
        "organName": () => `org_${i++}`,
      }]
    return [200, Mock.mock(template)]
  },
  '/memberexternal/GetEHealthMappingList': (config) => {
    const total = 124
    const { pageIndex, pageSize } = dbFunctions.getSearchParams(config)
    const currentPageSize = dbFunctions.getCurrentPageSize({ total, pageIndex, pageSize })
    const template = {
      'return_code': 0,
      'result_msg': 'Success',
      'data': { total, pageIndex, pageSize },
    }
    let i = pageIndex * pageSize + 1
    template.data[`eHealthMembers|${currentPageSize}`] = [{
      'eHealthMemberCode': /^[a-zA-Z0-9]{16}$/,
      'floorName': '樓層1',
      'roomName': '房間1',
      'photo': null,
      'status|1': ['MSF', 'MSBH', 'MSIH', 'MSE'],
      'memberID': () => i,
      'chineseName': '@CNAME',
      'englishName': '@NAME',
      'bedNumber': function () { return `101-${i++}` },
    }]
    return [200, Mock.mock(template)]
  },
  '/memberexternal/GetEHealthMapping': (config) => {
    const template = {
      'return_code': 0,
      'result_msg': 'Success',
      'data': {},
    }
    template.data[`memberEHealth`] = {
      'eHealthMemberCode': /^[a-zA-Z0-9]{16}$/,
      'companyName': '公司1',
      'facilityName': '院舍1',
      'floorName': '1/F',
      'roomName': '101',
      'memberCode': 'SSR0100001',
      'memberID': 1,
      'chineseName': '陈小文',
      'englishName': 'Wanwan Chan',
      'bedNumber': '101-1',
    }
    return [200, Mock.mock(template)]
  },
  '/memberexternal/UpdateEHealthMapping': () => [200, samples.Success],
  '/batch/GetVitalSignSamplePath': () => [200, samples.IoTAccessError],
  '/batch/BatchVitalSigns': () => [200, samples.IoTAccessError],
}

mocks.forEach(mock => {
  for (const api in ApiMap) {
    mock.onAny(new RegExp(`${api}\\b`))
      .reply(ApiMap[api])
  }
})
