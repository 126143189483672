import mocks from '@/@fake-db/mockAdapters'

mocks.forEach(mock => {
  mock.onAny(new RegExp('/notice/GetNoticeTypeDropDownList')).reply(() => [200, GetNoticeTypeDropDownList])
  mock.onAny(new RegExp('/notice/GetNoticeList')).reply(() => [200, GetNoticeList])
})

const GetNoticeTypeDropDownList = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "status": [
      {
        "statusCode": "NTFB",
        "statusDesc": "離床"
      }
    ]
  }
}

const GetNoticeList = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "total": 5,
    "pageIndex": 0,
    "pageSize": 0,
    "notices": [
      {
        "memberName": "陈大大文",
        "floorName": "5楼",
        "roomName": "501",
        "bedNumber": "9-001",
        "noticeDateTime": "2022-07-28T18:23:20",
        "noticeType": "NTFB"
      },
      {
        "memberName": "陈大大文",
        "floorName": "5楼",
        "roomName": "501",
        "bedNumber": "9-001",
        "noticeDateTime": "2022-07-28T18:22:20",
        "noticeType": "NTFB"
      },
      {
        "memberName": "陈大大文",
        "floorName": "5楼",
        "roomName": "501",
        "bedNumber": "9-001",
        "noticeDateTime": "2022-07-28T18:21:20",
        "noticeType": "NTFB"
      },
      {
        "memberName": "陈大大文",
        "floorName": "5楼",
        "roomName": "501",
        "bedNumber": "9-001",
        "noticeDateTime": "2022-07-28T18:20:20",
        "noticeType": "NTFB"
      },
      {
        "memberName": "陈大大文",
        "floorName": "5楼",
        "roomName": "501",
        "bedNumber": "9-001",
        "noticeDateTime": "2022-07-01T10:10:10",
        "noticeType": "NTFB"
      }
    ]
  }
}
