import mocks from '@/@fake-db/mockAdapters'

mocks.forEach(mock => {
  mock.onAny(new RegExp('/facility/GetFacilityModuleName'))
    .reply(() => [200, GetFacilityModuleName])
  mock.onAny(new RegExp('/facility/GetFacilityAvailableModules'))
    .reply(() => [200, GetFacilityAvailableModules])

  mock.onAny(new RegExp('/facility/GetFacilityDropDownList'))
    .reply(function (config) {
      const url = new URL(config.url)
      const params = new URLSearchParams(url.search)
      if (params.get('companyID') === '1') {
        return [200, GetFacilityDropDownList1]
      } else {
        return [200, GetFacilityDropDownList2]
      }
    })

  mock.onAny(new RegExp('/facility/GetFacilityList'))
    .reply(() => [200, GetFacilityList])
  mock.onAny(new RegExp('/facility/GetFacilityAndRoleList'))
    .reply(() => [200, GetFacilityAndRoleList])
  mock.onAny(new RegExp('/facility/GetFacility'))
    .reply(() => [200, GetFacility])
  mock.onAny(new RegExp('/facility/AddFacility'))
    .reply(() => [200, AddFacility])
  mock.onAny(new RegExp('/facility/UpdateFacility'))
    .reply(() => [200, UpdateFacility])
  mock.onAny(new RegExp('/facility/DeleteFacility'))
    .reply(() => [200, DeleteFacility])
  mock.onAny(new RegExp('/facility/GetModulesAndOtherConfigs'))
    .reply(() => [200, GetModulesAndOtherConfigs])
})

const GetFacilityList = {
  'return_code': 0,
  'result_msg': 'Success',
  'data': {
    'total': 3,
    'pageIndex': 0,
    'pageSize': 0,
    'facilitys': [
      {
        'facilityID': 1,
        'facilityCode': 'TEST1',
        'chineseName': '中文名稱-1',
        'englishName': 'English-1',
        'contactPhone': '34567891',
        'contractEndDay': '2029-12-17T00:00:00',
        'memberQuota': 11
      },
      {
        'facilityID': 2,
        'facilityCode': 'TEST2',
        'chineseName': '中文名稱-2',
        'englishName': 'English-2',
        'contactPhone': '34567892',
        'contractEndDay': '2029-12-17T00:00:00',
        'memberQuota': 12
      },
      {
        'facilityID': 3,
        'facilityCode': 'TEST3',
        'chineseName': '中文名稱-3',
        'englishName': 'English-3',
        'contactPhone': '34567893',
        'contractEndDay': '2029-12-17T00:00:00',
        'memberQuota': 13
      },
    ]
  }
}

const GetFacility = {
  'return_code': 0,
  'result_msg': 'Success',
  'data': {
    'facility': {
      'facilityID': 11,
      'facilityCode': 'TAC05',
      'companyID': 1,
      'isActive': false,
      'chineseName': '测试新增No5院舍',
      'englishName': 'Test Add No5 Facility',
      'chineseAddress': '大屿山某个地方',
      'englishAddress': 'DaYu Mountain somewhere',
      'contactPhone1': '77777777',
      'contactPhone2': '55555555',
      'contactFax': '66666666',
      'contactPerson': '陈大文经理',
      'memberQuota': 99,
      'contractEndDay': '2022-07-07T00:00:00',
      'selectedMenuStyleID': 1,
      'selectedLanguageCode': 'zh-HK',
      'bemSilentModeEndTime': '08:09:10',
      'selectedModuleIDs': [47, 14, 19, 24, 46, 66, 67, 70, 71, 81, 83, 84, 87, 89, 72, 10, 16, 35, 41, 53, 90, 91, 92, 93, 94, 95, 96, 97, 98],
      'callBellConfig': {
        'armedTimeOutHour': 2,
        'lowBattery': 167,
        'lostTimeOutHour': 3,
        'autoEmailIntervalHour': 4,
        'contactEmails': '1234@wesoft.com;4567@wesoft.com'
      },
    }
  }
}

const AddFacility = {
  'return_code': 0,
  'result_msg': 'Success',
  'data': {
    'facilityID': 4
  }
}

const UpdateFacility = {
  'return_code': 0,
  'result_msg': 'Success',
  'data': null
}

const DeleteFacility = {
  'return_code': 0,
  'result_msg': 'Success',
  'data': null
}

const GetModulesAndOtherConfigs = {
  'return_code': 0,
  'result_msg': 'Success',
  'data':
    {
      'modules': [
        {
          'code': 'Fac_Menu',
          'isSelected': true,
          'isOptional': false,
          'moduleID': 10,
          'name': '院舍',
          'parentID': null
        },
        {
          'code': 'Bed_Menu',
          'isSelected': true,
          'isOptional': false,
          'moduleID': 16,
          'name': '床位',
          'parentID': null
        },
        {
          'code': 'Sta_Menu',
          'isSelected': true,
          'isOptional': false,
          'moduleID': 35,
          'name': '職員',
          'parentID': null
        },
        {
          'code': 'Mem_Menu',
          'isSelected': true,
          'isOptional': false,
          'moduleID': 41,
          'name': '會員',
          'parentID': null
        },
        {
          'code': 'Gro_Menu',
          'isSelected': true,
          'isOptional': false,
          'moduleID': 47,
          'name': '組別',
          'parentID': null
        },
        {
          'code': 'Role_Menu',
          'isSelected': true,
          'isOptional': false,
          'moduleID': 53,
          'name': '職位',
          'parentID': null
        },
        {
          'code': 'Mon_Menu',
          'isSelected': false,
          'isOptional': true,
          'moduleID': 66,
          'name': '離床裝置',
          'parentID': null
        },
        {
          'code': 'Mon_Menu_View',
          'isSelected': false,
          'isOptional': true,
          'moduleID': 67,
          'name': '離床裝置信息總覽',
          'parentID': 66
        },
        {
          'code': 'Mon_Menu_Reset',
          'isSelected': false,
          'isOptional': true,
          'moduleID': 70,
          'name': '離床裝置信息強制設定',
          'parentID': 66
        },
        {
          'code': 'Mon_Menu_Report',
          'isSelected': false,
          'isOptional': true,
          'moduleID': 71,
          'name': '離床裝置報告',
          'parentID': 66
        },
        {
          'code': 'Mon_Batch_Upload',
          'isSelected': false,
          'isOptional': true,
          'moduleID': 81,
          'name': '批量上傳',
          'parentID': 66
        },
        {
          'code': 'CallBell_Menu',
          'isSelected': false,
          'isOptional': true,
          'moduleID': 83,
          'name': '緊急鍵',
          'parentID': null
        },
        {
          'code': 'CallBell_Menu_Record',
          'isSelected': false,
          'isOptional': true,
          'moduleID': 84,
          'name': '緊急鍵記錄',
          'parentID': 83
        },
        {
          'code': 'CallBell_Menu_Report',
          'isSelected': false,
          'isOptional': true,
          'moduleID': 87,
          'name': '緊急鍵報告',
          'parentID': 83
        },
        {
          'code': 'CallBell_Menu_Batch_Upload',
          'isSelected': false,
          'isOptional': true,
          'moduleID': 89,
          'name': '緊急鍵批量上傳',
          'parentID': 83
        },
        {
          'code': 'CallBell_Menu_NFC_Manage',
          'isSelected': false,
          'isOptional': true,
          'moduleID': 90,
          'name': 'NFC標簽管理',
          'parentID': 83
        },
        {
          'code': 'CallBell_Menu_Patrol_Record',
          'isSelected': false,
          'isOptional': true,
          'moduleID': 91,
          'name': '巡邏記錄',
          'parentID': 83
        },
        {
          'code': 'CallBell_Menu_Patrol_Report',
          'isSelected': false,
          'isOptional': true,
          'moduleID': 92,
          'name': '巡邏報告',
          'parentID': 83
        },
        {
          'code': 'Notice_Menu',
          'isSelected': false,
          'isOptional': true,
          'moduleID': 72,
          'name': '通知中心',
          'parentID': null
        }],
      'otherConfigs':
        {
          'menuStyle':
            {
              'moduleID': 41,
              'moduleCode': 'Mem_Menu',
              'moduleName': '會員',
              'labels': [
                {
                  'labelID': 1,
                  'text': '會員'
                },
                {
                  'labelID': 2,
                  'text': '院友'
                },
                {
                  'labelID': 3,
                  'text': '學員'
                }],
              'selectedLabelID': null,
              'selectedLabelText': ''
            },
          'languageOption':
            {
              'languages': [
                {
                  'code': 'zh-HK',
                  'name': '繁體中文'
                },
                {
                  'code': 'en-US',
                  'name': 'English'
                }],
              'selectedLanguageCode': 'zh-HK',
              'selectedLanguageName': '繁體中文'
            },
          'bemSilentModeEndTime': '07:00:00'
        }
    }
}

const GetFacilityAndRoleList = {
  'return_code': 0,
  'result_msg': 'Success',
  'data': {
    'list': [
      {
        'facilityID': 2,
        'facilityCode': 'GRT01',
        'facilityName': '常青树No.1院舍',
        'roles': [
          {
            'roleID': 3,
            'roleCode': 'FA',
            'roleName': '院舍管理員'
          },
          {
            'roleID': 4,
            'roleCode': 'FS',
            'roleName': '院舍職員'
          },
          {
            'roleID': 5,
            'roleCode': 'TFS',
            'roleName': '常青树职员'
          }
        ]
      },
      {
        'facilityID': 3,
        'facilityCode': 'GRT02',
        'facilityName': '常青树No.2院舍',
        'roles': [
          {
            'roleID': 3,
            'roleCode': 'FA',
            'roleName': '院舍管理員'
          },
          {
            'roleID': 4,
            'roleCode': 'FS',
            'roleName': '院舍職員'
          }
        ]
      },
    ]
  }
}

const GetFacilityDropDownList1 = {
  'return_code': 0,
  'result_msg': 'Success',
  'data': {
    'facilitys': [
      {
        'facilityID': 11,
        'facilityName': '院舍1-1'
      },
      {
        'facilityID': 12,
        'facilityName': '院舍1-2'
      },
      {
        'facilityID': 13,
        'facilityName': '院舍1-3'
      },
    ]
  }
}
const GetFacilityDropDownList2 = {
  'return_code': 0,
  'result_msg': 'Success',
  'data': {
    'facilitys': [
      {
        'facilityID': 21,
        'facilityName': '院舍2-1'
      },
      {
        'facilityID': 22,
        'facilityName': '院舍2-2'
      },
      {
        'facilityID': 23,
        'facilityName': '院舍2-3'
      },
    ]
  }
}

const GetFacilityAvailableModules = {
  'return_code': 0,
  'result_msg': 'Success',
  'data': {
    'modules': [
      {
        'moduleID': 10,
        'name': '院舍',
        'parentID': null
      },
      {
        'moduleID': 35,
        'name': '職員',
        'parentID': null
      },
      {
        'moduleID': 47,
        'name': '組別',
        'parentID': null
      },
      {
        'moduleID': 66,
        'name': '離床裝置',
        'parentID': null
      },
      {
        'moduleID': 67,
        'name': '離床裝置信息總覽',
        'parentID': 66
      },
      {
        'moduleID': 68,
        'name': '離床装置管理',
        'parentID': 66
      },
      {
        'moduleID': 69,
        'name': '離床裝置信息強制設定',
        'parentID': 66
      },
      {
        'moduleID': 70,
        'name': '離床裝置報告',
        'parentID': 66
      }
    ]
  }
}

const GetFacilityModuleName = {
  'return_code': 0,
  'result_msg': 'Success',
  'data': {
    'zh-HK': {
      'member': '會員',
    },
    'en-US': {
      'member': 'member',
    }
  }
}
