import mocks from '@/@fake-db/mockAdapters'

mocks.forEach(mock => {
  mock.onAny(new RegExp('/WebLogin/GenToken')).reply(() => [200, GenToken])
})

const GenToken = {
  "return_code": 0,
  "result_msg": "Success",
  "token": "eyJhbGciOiJSUzI1NiIsImtpZCI6IjVGNjg5NUU3NjMzRjIxMzZBRERENjQ4QzIzRjM4OEVCIiwidHlwIjoiYXQrand0In0.eyJuYmYiOjE2NDg3MDk2NjcsImV4cCI6MTY0ODcxNjg2NywiaXNzIjoiaHR0cDovL2xvY2FsaG9zdDo1MDAxIiwiYXVkIjpbIkF0dGVuZFNlcnZpY2UiLCJNZW1iZXJTZXJ2aWNlIiwiU3RhZmZTZXJ2aWNlIl0sImNsaWVudF9pZCI6Ind4ZDkzMGVhNWQ1YTI1OGY0ZiIsInN1YiI6InNhQHdlaGVhbHRoLmNvbSIsImF1dGhfdGltZSI6MTY0ODcwOTY2NywiaWRwIjoibG9jYWwiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9zaWQiOiIxIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbmFtZSI6Ildlc29mdEFkbWluIiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvZW1haWxhZGRyZXNzIjoic2FAd2VoZWFsdGguY29tIiwiSW1wZXJzb25hdGVkSUQiOiIiLCJJbXBlcnNvbmF0ZWROYW1lIjoiIiwiSW1wZXJzb25hdGVkRW1haWwiOiIiLCJqdGkiOiJCQzQ1QzA2NkJFMjUzNUQ4NEVCQkI0QjEwMkJBMTAwQSIsImlhdCI6MTY0ODcwOTY2Nywic2NvcGUiOlsiQXR0ZW5kU2VydmljZSIsIk1lbWJlclNlcnZpY2UiLCJvcGVuaWQiLCJwcm9maWxlIiwiU3RhZmZTZXJ2aWNlIiwib2ZmbGluZV9hY2Nlc3MiXSwiYW1yIjpbImN1c3RvbSJdfQ.jJ1AYdmPIofnb-6-oOrjlTdhfuMEh3VtZGhyKIkTIVwLxPyWL0m87wuHrIDPYkfKWnkCaRtciArP6oiwp6vf283bqP72D5eEyy7yigsPDZugeThPDQUhV7lv9Lc_K5fllFqYbfTHkZHGr7U62RiTKaZmZ9qYE82oe3C-__VAbRI8OROlZYNUn_9mZNoCj7sesk_vsq6Yx65JnQM_tbQNra072VF3ZN8iFTnRwA-fSeqlhri1pMEXW5Edsm2hxBvW2BHYKSAN-aQKB8W0xKWH8Rd_DfLoP6C_ueEEARLH_wMrfT5kgjss7E_6Ou3SIAzVPtS9LNs8Wo1tc05ngo0NeA",
  "expire_in": 7200,
  "refresh_token": "F0E35A4E7EBC6533A461E3166AE8F894B9B9AAF147EFB17FD907E6D161A93F1F",
  "remainingTime": null,
  "loginID": null
}
