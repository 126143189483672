import Vue from 'vue'
import axios from 'axios'
import common from '@/common'
import globalConfig from '@/global-config'
import router from '@/router'
import backendApiList from '@/backendApiList'

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL + 'api',
  timeout: common.config.api_timeout,
  headers: common.getApiHeaders(),
})

axiosIns.interceptors.request.use(
  (reqConfig) => {
    reqConfig = Object.assign({
      whShowGlobalOverlay: true,
      whShowErrorMsgWhen200: true,
    }, reqConfig)

    if (reqConfig.whShowGlobalOverlay) {
      common.showLoading()
    }

    if (!common.config.is_request_mock && common.config.check_backend_api) {
      let tmpUrl = reqConfig.url.split('?')[0]
      if (!backendApiList.includes(tmpUrl)) {
        console.error('API not in backendApiList: ' + tmpUrl)

        // this will trigger the axiosIns.interceptors.response "onRejected" first
        return Promise.reject({
          rejectedInReq: true,
          config: reqConfig,
        })
      }
    }
    return reqConfig
  })

if (globalConfig.is_request_mock) {
  axiosIns.interceptors.response.use(
    (res) => {
      let requestData = {}
      console.group('Mock', res.request.responseURL)
      try {
        if (typeof (res.config.data) === 'string') {
          requestData = JSON.parse(res.config.data)
        } else if (res.config.data instanceof FormData) {
          requestData = Object.fromEntries(res.config.data.entries())
        } else {
          requestData = res.config.data
        }
        res.config.dataObj = requestData
      } catch (err) {
        console.log(err)
      }
      console.log(res)
      console.groupEnd()
      return res
    },
    (error) => {
      console.group('Mock', error.config.url)
      console.dir(error)
      console.groupEnd()
      return Promise.reject(error)
    })
}

axiosIns.interceptors.response.use(
  (res) => {
    // Any status code that lie within the range of 2xx cause this function to trigger

    if (res.config.whShowGlobalOverlay) {
      common.hideLoading()
    }

    // while error occur in status 2xx
    if (res.data.return_code !== 0) {
      if (res.config.whShowErrorMsgWhen200) {
        const errorMsg = res.data.result_msg ? common.getI18n('error.' + res.data.result_msg) : common.getI18n('error.UnknownError')
        common.showAlert({ title: errorMsg })

        // this won't trigger the axiosIns.interceptors.response "onRejected" just below, but could be caught in concrete request
        return Promise.reject(new Error(`Error occurred in a status-200 response ("${res.config.url}")`))
      }

    }

    return res.data
  },
  (err) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger

    if (err.config?.whShowGlobalOverlay) {
      common.hideLoading()
    }

    if (err.rejectedInReq === true) {
      return Promise.reject(err)
    }

    const errMsg = err.message ? err.message : ''
    const errStatus = err.response ? err.response.status : undefined
    if (errMsg.toLowerCase().indexOf('timeout') >= 0) {
      common.showAlert({ title: common.getI18n('error.' + 'apiRequestTimeout') })
    } else if (errMsg.toLowerCase().indexOf('network error') >= 0) {
      common.showAlert({ title: common.getI18n('error.' + 'apiRequestNetworkError') })
    } else if (errStatus === 400) {
      if (err.response.data?.errors) {
        let errorMsg = 'Error:'
        for (const i in err.response.data.errors) {
          errorMsg += ' ' + err.response.data.errors[i][0]
        }
        common.showAlert({ title: errorMsg })
      } else {
        common.showAlert({ title: common.getI18n('error.' + 'UnknownError') + ': ' + errStatus })
      }
    } else if (errStatus === 401 || errStatus === 403) {
      common.logout()
    } else if (errStatus === 404) {
      common.showAlert({ title: common.getI18n('error.' + 'UrlNotFound') })
    } else if (500 <= errStatus && errStatus <= 599) {
      common.showAlert({ title: common.getI18n('error.' + 'ServerError') + ': ' + errStatus })
    } else if (errMsg) {
      common.showAlert({ title: errMsg })
    } else {
      const extraMsg = errStatus ? ': ' + errStatus : ''
      this.showAlert({ title: this.getI18n('error.UnknownError') + extraMsg })
    }

    return Promise.reject(err)
  })

Vue.prototype.$requestWehealth = axiosIns

export default axiosIns
