import mocks from '@/@fake-db/mockAdapters'

mocks.forEach(mock => {
  mock.onAny(new RegExp('/bedfloor/GetRoomDropDownList')).reply(() => [200, GetRoomDropDownList])
  mock.onAny(new RegExp('/bedfloor/GetFloorDropDownList')).reply(() => [200, GetFloorDropDownList])
  mock.onAny(new RegExp('/bedfloor/GetFloorList')).reply(() => [200, GetFloorList])
  mock.onAny(new RegExp('/bedfloor/GetFloor')).reply(() => [200, GetFloor])
  mock.onAny(new RegExp('/bedfloor/AddFloor')).reply(() => [200, AddFloor])
  mock.onAny(new RegExp('/bedfloor/UpdateFloor')).reply(() => [200, UpdateFloor])
  mock.onAny(new RegExp('/bedfloor/UpdateFloorSeq')).reply(() => [200, UpdateFloorSeq])
  // mock.onAny(new RegExp('/floor/DeleteFloor')).reply(() => [200, DeleteFloor])
})

const GetFloorList = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "total": 12,
    "pageIndex": 0,
    "pageSize": 0,
    "floors": [
      {
        "floorID": 1,
        "floorName": "樓層1",
        "roomNames": [101, 102],
        "isActive": true,
      },
      {
        "floorID": 2,
        "floorName": "樓層2",
        "roomNames": [201, 202],
        "isActive": true,
      },
      {
        "floorID": 3,
        "floorName": "樓層3",
        "roomNames": [301, 302],
        "isActive": false,
      },
      {
        "floorID": 4,
        "floorName": "樓層4",
        "roomNames": [401, 402],
        "isActive": false,
      },
      {
        "floorID": 5,
        "floorName": "樓層5",
        "roomNames": [301, 302],
        "isActive": false,
      },
      {
        "floorID": 6,
        "floorName": "樓層6",
        "roomNames": [301, 302],
        "isActive": false,
      },
      {
        "floorID": 7,
        "floorName": "樓層7",
        "roomNames": [301, 302],
        "isActive": false,
      },
      {
        "floorID": 8,
        "floorName": "樓層8",
        "roomNames": [301, 302],
        "isActive": false,
      },
      {
        "floorID": 9,
        "floorName": "樓層9",
        "roomNames": [301, 302],
        "isActive": false,
      },
      {
        "floorID": 10,
        "floorName": "樓層10",
        "roomNames": [301, 302],
        "isActive": false,
      },
      {
        "floorID": 11,
        "floorName": "樓層11",
        "roomNames": [301, 302],
        "isActive": false,
      },
      {
        "floorID": 12,
        "floorName": "樓層12",
        "roomNames": [301, 302],
        "isActive": false,
      },
    ]
  }
}

const GetFloor = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "bedFloor": {
      "companyID": 1,
      "facilityID": 11,
      "floorID": 3,
      "floorName": "一樓",
      "isActive": true,
      "rooms": [
        {roomID: 1, roomName: '101', isActive: true},
        {roomID: 2, roomName: '102', isActive: false},
        {roomID: 3, roomName: '103', isActive: true},
      ]
    }
  }
}

const AddFloor = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "floorID": 4
  }
}

const UpdateFloor = {
  "return_code": 0,
  "result_msg": "Success",
  "data": null
}

const DeleteFloor = {
  "return_code": 0,
  "result_msg": "Success",
  "data": null
}

const UpdateFloorSeq = {
  "return_code": 0,
  "result_msg": "Success",
  "data": null
}

const GetFloorDropDownList = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "floors": [
      {
        "floorID": 1,
        "floorName": "樓層1"
      },
      {
        "floorID": 2,
        "floorName": "樓層2"
      },
    ]
  }
}

const GetRoomDropDownList = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "rooms": [
      {
        "roomID": 1,
        "roomName": "房間1"
      },
      {
        "roomID": 2,
        "roomName": "房間2"
      },
    ]
  }
}

