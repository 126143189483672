import mocks from '@/@fake-db/mockAdapters'

mocks.forEach(mock => {
  mock.onAny(new RegExp('/admin/GetAdminList'))
    .reply(() => [200, GetAdminList])
  mock.onAny(new RegExp('/admin/GetAdmin'))
    .reply(() => [200, GetAdmin])
})

const GetAdminList = {
  'return_code': 0,
  'result_msg': 'Success',
  'data': {
    'total': 5,
    'pageIndex': 0,
    'pageSize': 10,
    'admins': [{
      'adminID': 30,
      'adminCode': 'WVA008',
      'chineseName': '伊娃',
      'englishName': 'eva',
      'isActive': true
    }, {
      'adminID': 29,
      'adminCode': 'SA002',
      'chineseName': 'SA2號',
      'englishName': 'SA 2',
      'isActive': true
    }, {
      'adminID': 28,
      'adminCode': 'VA003',
      'chineseName': 'VA3號',
      'englishName': 'VA 3',
      'isActive': true
    }, {
      'adminID': 27,
      'adminCode': 'VA002',
      'chineseName': 'VA2號lalalala',
      'englishName': 'VA 2',
      'isActive': true
    }, {
      'adminID': 2,
      'adminCode': 'VA001',
      'chineseName': '陈晓强',
      'englishName': 'Little Strong',
      'isActive': true
    }]
  }
}

const GetAdmin = {
  'return_code': 0,
  'result_msg': 'Success',
  'data': {
    'admin': {
      'loginName': 'eva',
      'contactEmail': '',
      'contactPhone': null,
      'adminID': 30,
      'adminCode': 'WVA008',
      'chineseName': '伊娃',
      'englishName': 'eva',
      'isActive': true
    }
  }
}
