import mocks from '@/@fake-db/mockAdapters'

mocks.forEach(mock => {
  mock.onAny(new RegExp('/company/GetCompanyDropDownList')).reply(() => [200, GetCompanyDropDownList])
  mock.onAny(new RegExp('/company/GetCompanyList')).reply(() => [200, GetCompanyList])
  mock.onAny(new RegExp('/company/GetCompany')).reply(() => [200, GetCompany])
  mock.onAny(new RegExp('/company/AddCompany')).reply(() => [200, AddCompany])
  mock.onAny(new RegExp('/company/UpdateCompany')).reply(() => [200, UpdateCompany])
  mock.onAny(new RegExp('/company/DeleteCompany')).reply(() => [200, DeleteCompany])
})

const GetCompanyList = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "total": 3,
    "pageIndex": 0,
    "pageSize": 0,
    "companys": [
      {
        "companyID": 1,
        "companyCode": "編號-1",
        "chineseName": "中文名-1",
        "englishName": "english-1",
        "contactPhone": "12345678",
        "contractEndDay": "2022-03-25T00:00:00",
        "memberQuota": 1
      },
      {
        "companyID": 2,
        "companyCode": "編號-2",
        "chineseName": "中文名-2",
        "englishName": "english-2",
        "contactPhone": "123456780",
        "contractEndDay": "2022-03-25T00:00:00",
        "memberQuota": 1
      },
      {
        "companyID": 3,
        "companyCode": "編號-3",
        "chineseName": "中文名-3",
        "englishName": "english-3",
        "contactPhone": "123456789",
        "contractEndDay": "2022-03-25T00:00:00",
        "memberQuota": 1
      },
    ]
  }
}

const GetCompany = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "company": {
      "companyID": 3,
      "companyCode": "TEST",
      "facilitys": null,
      "chineseName": "測試公司",
      "englishName": "Test Company",
      "chineseAddress": "測試中文地址",
      "englishAddress": "Test Address",
      "contactPhone": "26547899",
      "contactFax": "54548778",
      "contactPerson": "測試聯繫人",
      "memberQuota": 300,
      "contractEndDay": "2025-03-26T00:00:00",
      "isSingleFacility": true
    }
  }
}

const AddCompany = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "companyID": 4
  }
}

const UpdateCompany = {
  "return_code": 0,
  "result_msg": "Success",
  "data": null
}

const DeleteCompany = {
  "return_code": 0,
  "result_msg": "Success",
  "data": null
}

const GetCompanyDropDownList = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "companys": [
      {
        "companyID": 1,
        "companyCode": "SSR",
        "companyName": "公司1"
      },
      {
        "companyID": 2,
        "companyCode": "GRT",
        "companyName": "公司2"
      }
    ]
  }
}

