import mocks from '@/@fake-db/mockAdapters'
import samples from '@/@fake-db/responseSamples'

const ApiMap = {
  // CallBell Record
  '/callbell/GetArmedList': () => [200, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': {
      'total': 3,
      'pageIndex': 0,
      'pageSize': 0,
      'callBells': [
        {
          'armedID': 3,
          'memberPhoto': 'files/MemberPhoto/MemberPhoto_29_20220526154455893.png',
          'floorName': '地庫',
          'roomName': 'B1-01',
          'memberName': '李肆',
          'bedNumber': 'B1-01-101',
          'battery': 158,
          'armedTime': '2022-05-28T14:18:55',
          'followedTime': null,
          'followedStaff': null,
          'isConfirmed': false
        },
        {
          'armedID': 2,
          'memberPhoto': 'files/MemberPhoto/MemberPhoto_29_20220511171624975.png',
          'floorName': '地庫',
          'roomName': 'B1-01',
          'memberName': '李伍',
          'bedNumber': 'B1-01-103',
          'battery': 201,
          'armedTime': '2022-05-27T22:14:45',
          'followedTime': null,
          'followedStaff': null,
          'isConfirmed': false
        },
        {
          'armedID': 1,
          'memberPhoto': 'files/MemberPhoto/MemberPhoto_29_20220512181701530.png',
          'floorName': '地庫',
          'roomName': 'B1-01',
          'memberName': '李陸',
          'bedNumber': 'B1-01-102',
          'battery': 48,
          'armedTime': '2022-05-27T10:08:33',
          'followedTime': '2022-10-17T17:39:30',
          'followedStaff': '000200634D3B1721',
          'isConfirmed': true
        },
      ]
    }
  }],

  '/callbell/GetBatteryList': () => [200, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': {
      'total': 5,
      'pageIndex': 0,
      'pageSize': 20,
      'callBells': [{
        'battery': null,
        'lastUpdateTime': null,
        'callBellID': 11,
        'deviceMAC': 'FFEEDDCCBBAA0009',
        'deviceName': '02-CB-009',
        'bedID': 535,
        'floorName': '一楼',
        'roomName': '101',
        'bedNumber': '101-05'
      }, {
        'battery': null,
        'lastUpdateTime': null,
        'callBellID': 10,
        'deviceMAC': 'FFEEDDCCBBAA0008',
        'deviceName': '02-CB-008',
        'bedID': 530,
        'floorName': '一楼',
        'roomName': '101',
        'bedNumber': '101-03'
      }, {
        'battery': null,
        'lastUpdateTime': null,
        'callBellID': 8,
        'deviceMAC': 'FFEEDDCCBBAA0010',
        'deviceName': '监控点111',
        'bedID': 534,
        'floorName': '一楼',
        'roomName': '101',
        'bedNumber': '101-04'
      }, {
        'battery': '100%',
        'lastUpdateTime': '2023-01-10T15:37:00',
        'callBellID': 7,
        'deviceMAC': 'FFEEDDCCBBAA0007',
        'deviceName': '02-CB-007',
        'bedID': 528,
        'floorName': '一楼',
        'roomName': '101',
        'bedNumber': '101-01'
      }, {
        'battery': null,
        'lastUpdateTime': null,
        'callBellID': 6,
        'deviceMAC': 'FFEEDDCCBBAA0006',
        'deviceName': '抢来的006',
        'bedID': 536,
        'floorName': '一楼',
        'roomName': '101',
        'bedNumber': '101-06'
      }]
    }
  }],

  // CallBell Environment
  '/callbell/GetCallBellList': () => [200, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': {
      'total': 4,
      'pageIndex': 0,
      'pageSize': 0,
      'callBells': [
        {
          'deviceMAC': 'AD01000EC2000607',
          'deviceName': 'Button-A01',
          'isUAT': true
        },
        {
          'deviceMAC': 'AD01000EC2000605',
          'deviceName': 'Button-A02',
          'isUAT': true
        },
        {
          'deviceMAC': 'AD01000EC2000603',
          'deviceName': 'Button-A03',
          'isUAT': false
        },
        {
          'deviceMAC': 'AD01000EC2000601',
          'deviceName': 'Button-A04',
          'isUAT': false
        }
      ]
    }
  }],
  '/callbell/AddCallBell': () => [200, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': null
  }],
  '/callbell/AssignCallBell': () => [200, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': null
  }],
  '/callbell/DeleteCallBell': () => [200, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': null
  }],
  '/callbell/ExportCallBellList': () => [200, samples.IoTAccessError],

  // CallBell Facility
  '/callbell/GetFacilityCallBellList': () => [200, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': {
      'total': 4,
      'pageIndex': 0,
      'pageSize': 0,
      'callBells': [
        {
          'companyName': '公司1',
          'facilityName': '院舍1',
          'callBellID': 1,
          'deviceMAC': '7b3b7b15a379452a',
          'deviceName': 'Button-A01',
          'floorName': '5楼',
          'roomName': '501',
          'bedNumber': '501-01'
        },
        {
          'companyName': '公司1',
          'facilityName': '院舍1',
          'callBellID': 2,
          'deviceMAC': 'aca0a4dfaf94c9d4',
          'deviceName': 'Button-A02',
          'floorName': '5楼',
          'roomName': '501',
          'bedNumber': '501-02'
        },
        {
          'status': 'Offline',
          'companyName': '公司1',
          'facilityName': '院舍1',
          'callBellID': 3,
          'deviceMAC': '192d1e02a50e4c70',
          'deviceName': 'Button-A03',
          'floorName': '5楼',
          'roomName': '501',
          'bedNumber': '501-03'
        },
        {
          'companyName': '公司1',
          'facilityName': '院舍1',
          'callBellID': 4,
          'deviceMAC': '192d1e02a50e4c79',
          'deviceName': 'Button-A04',
          'floorName': '5楼',
          'roomName': '501',
          'bedNumber': '501-04'
        },
        {
          'companyName': null,
          'facilityName': null,
          'callBellID': 4,
          'deviceMAC': '84441deacf135f3f',
          'floorName': null,
          'roomName': null,
          'bedNumber': null
        }
      ]
    }
  }],
  '/callbell/GetFacilityCallBell': () => [200, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': {
      'callBell': {
        'callBellID': 1,
        'deviceMAC': '84441DEACF135F3F',
        'deviceName': 'Button-A01',
        'companyID': 1,
        'facilityID': 11,
        'floorID': null,
        'roomID': null,
        'bedID': null
      }
    }
  }],

  // Nfc
  '/staffnfc/GetNfcList': () => [200, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': {
      'total': 4,
      'pageIndex': 0,
      'pageSize': 0,
      'nfcs': [
        {
          'nfcTag': '002400634E6F3DD9',
          'staffID': 11,
          'staffCode': 'SA001',
          'chineseName': '張壹',
          'englishName': 'AlissaZhang',
          'isActive': true
        },
        {
          'nfcTag': '002400634E6F3DD8',
          'staffID': 12,
          'staffCode': 'WSE002',
          'chineseName': '張貳',
          'englishName': 'WayneZhang',
          'isActive': true
        },
        {
          'nfcTag': '002400634E6F3DD7',
          'staffID': 13,
          'staffCode': 'WSE001',
          'chineseName': '張叁',
          'englishName': 'IronZhang',
          'isActive': false
        },
      ]
    }
  }],

  // CallBell Report
  '/callbell/GetArmedReport': () => [404, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': null
  }],

  // Patrol Report
  '/callbell/GetPatrolReport': () => [404, {
    'r\eturn_code': 0,
    'result_msg': 'Success',
    'data': null
  }],

  // Patrol Record
  '/callbell/GetPatrolList': () => [200, {
    'return_code': 0,
    'result_msg': 'Success',
    'data': {
      'total': 4,
      'pageIndex': 0,
      'pageSize': 0,
      'callBells': [
        {
          'patrolID': 1,
          'patrolStaff': '張叁',
          'floorName': '一樓',
          'roomName': '101',
          'bedNumber': '101-01',
          'callBellName': 'Button-A03',
          'patrolTime': '2022-05-28T14:20:55',
        },
        {
          'patrolID': 2,
          'patrolStaff': '張壹',
          'floorName': '地庫',
          'roomName': 'B1-01',
          'bedNumber': 'B1-01-101',
          'callBellName': 'Button-A04',
          'patrolTime': '2022-05-22T14:20:55',
        },
      ]
    }
  }],

  // RawDown (tech)
  '/callbell/DownloadTechRawData': () => [200, samples.GatewayTimeout_200],

  '/batch/BatchCallBells': () => [200, samples.GatewayTimeout_200]

}

mocks.forEach(mock => {
  for (const api in ApiMap) {
    mock.onAny(new RegExp(api))
      .reply(ApiMap[api])
  }
})
