import mocks from '@/@fake-db/mockAdapters'

mocks.forEach(mock => {
  mock.onAny(new RegExp('/member/GetMemberStatusDropDownList')).reply(() => [200, GetMemberStatusDropDownList])
  mock.onAny(new RegExp('/member/GetMemberList')).reply(() => [200, GetMemberList])
  mock.onAny(new RegExp('/member/GetMember')).reply(() => [200, GetMember])
  mock.onAny(new RegExp('/member/AddMember')).reply(() => [200, AddMember])
  mock.onAny(new RegExp('/member/UpdateMember')).reply(() => [200, UpdateMember])
  mock.onAny(new RegExp('/member/DeleteMember')).reply(() => [200, DeleteMember])
  mock.onAny(new RegExp('/memberphoto/AddPhoto')).reply(() => [200, AddPhoto])
  mock.onAny(new RegExp('/memberphoto/UpdatePhoto')).reply(() => [200, UpdatePhoto])
})

const GetMemberList = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "total": 4,
    "pageIndex": 0,
    "pageSize": 0,
    "members": [
      {
        "floorName": "2/F",
        "roomName": "201",
        "memberID": 16,
        "chineseName": "張三",
        "englishName": "Three Zh",
        "bedNumber": "201-01",
        "photo": "files/MemberPhoto/MemberPhoto_29_20220526154455893.png",
        "status": "MSIH"
      },
      {
        "floorName": "1/F",
        "roomName": "101",
        "memberID": 15,
        "chineseName": "李肆",
        "englishName": "Four L",
        "bedNumber": "101-02",
        "photo": "files/MemberPhoto/MemberPhoto_29_20220529183722477.jpg",
        "status": "MSF"
      },
      {
        "floorName": "3/F",
        "roomName": "302",
        "memberID": 13,
        "chineseName": "陳伍",
        "englishName": "Five Ch",
        "bedNumber": "302-01",
        "photo": "files/MemberPhoto/MemberPhoto_29_20220512181701530.png",
        "status": "MSF"
      },
      {
        "floorName": "3/F",
        "roomName": "301",
        "memberID": 12,
        "chineseName": "趙陸",
        "englishName": "Six Zh",
        "bedNumber": "301-01",
        "photo": "files/MemberPhoto/MemberPhoto_29_20220511171624975.png",
        "status": "MSE"
      }
    ]
  }
}

const GetMember = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "member": {
      "companyID": 1,
      "facilityID": 11,
      "floorID": 1,
      "roomID": 1,
      "bedID": 1,
      "memberCode": 'Member-01',
      "refCode": 'A123',
      "chineseName": "阿朱",
      "englishName": "Arjuna",
      "hkid": '123456',
      "emergencyPerson": '阿達',
      "emergencyPhone": '12345678',
      "isMale": false,
      "birthDate": '1960-01-01',
      "joinedDate": '2020-01-01',
      "status": "MSF",
      "rfidTags": [
        "A02226000003A02226000002",
        "A02226000003A02226000003",
        "A02226000003A02226000004"
      ],
    }
  }
}

const AddMember = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "floorID": 4
  }
}

const UpdateMember = {
  "return_code": 0,
  "result_msg": "Success",
  "data": null
}

const DeleteMember = {
  "return_code": 0,
  "result_msg": "Success",
  "data": null
}

const AddPhoto = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "photoID": 1
  }
}

const UpdatePhoto = {
  "return_code": 0,
  "result_msg": "Success",
  "data": null
}

const GetMemberStatusDropDownList = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "status": [
      {
        "statusCode": "MSF",
        "statusDesc": "在院舍"
      },
      {
        "statusCode": "MSBH",
        "statusDesc": "在家中"
      },
      {
        "statusCode": "MSIH",
        "statusDesc": "在醫院"
      },
      {
        "statusCode": "MSE",
        "statusDesc": "已退出"
      }
    ]
  }
}
