import mocks from '@/@fake-db/mockAdapters'

mocks.forEach(mock => {
  mock.onAny(new RegExp('/role/GetRoleList')).reply(() => [200, GetRoleList])
  mock.onAny(new RegExp('/role/GetRole')).reply(() => [200, GetRole])
  mock.onAny(new RegExp('/role/AddRole')).reply(() => [200, AddRole])
  mock.onAny(new RegExp('/role/UpdateRole')).reply(() => [200, UpdateRole])
  mock.onAny(new RegExp('/role/DeleteRole')).reply(() => [200, DeleteRole])
})

const GetRoleList = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "total": 3,
    "pageIndex": 0,
    "pageSize": 0,
    "roles": [
      {
        "roleID": 1,
        "roleName": "角色1",
        "isActive": true
      },
      {
        "roleID": 2,
        "roleName": "角色2",
        "isActive": false
      },
    ]
  }
}

const GetRole = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "role": {
      "roleID": 1,
      "roleName": "角色1",
      "isActive": true,
      "permissionIDs": [1, 2]
    }
  }
}

const AddRole = {
  "return_code": 0,
  "result_msg": "Success",
  "data": {
    "floorID": 4
  }
}

const UpdateRole = {
  "return_code": 0,
  "result_msg": "Success",
  "data": null
}

const DeleteRole = {
  "return_code": 0,
  "result_msg": "Success",
  "data": null
}
